body {
    color: RGB(55, 55, 55);
    font-size: 16px !important;
    font-weight: 400;
    background-color:  RGB(248, 249, 255);
}

._1NNm_ {
    font-size: 18px;
}

._RUwxw {
    font-size: 16px;
}

._cVbmQ {
    font-size: 14px;
}

._3zsS- {
    color: RGB(55, 55, 55);
}

._3UanZ {
    color: RGB(111, 111, 111) !important;
}

._3BgtN {
    background-size: contain;
    background-color: white;
    background-repeat: no-repeat;
    background-position: center;
}

._36J6m {
    font-size: 16px;
    font-weight: 700;
    font-family: Arial;
}

._2naL5:hover {
  color: RGB(55, 55, 55);
  cursor: pointer;
}

._2naL5._1bLoa {
  color: RGB(55, 55, 55);
}

._3PtqJ {
  color: RGB(55, 55, 55);
  font-size: 20px;
  font-weight: 300;
  font-family: Helvetica;
}

._2y_1w {
  padding: 0px !important;
  margin-bottom: 1em !important; 
  padding-bottom: 1em !important;
}

._2-AtU {
  border: 1px solid #1AB2E9 !important;
  padding-top: 9px;
  padding-left: 9px;
  border-radius: 0.1875rem;
  padding-bottom: 7px;
  background-color:#1AB2E9 !important;
}

._1Eez8 {
  color: RGB(55, 55, 55);
  font-size: 14px;
  font-weight: 700;
  font-family: Helvetica;
}

._2-AtU:hover {
  cursor: pointer;
}

._24Z1n {
  color: RGB(55, 55, 55);
  font-size: 16px;
  font-weight: 700;
  font-family: Arial;
  padding-bottom: 5px;
}

._2k5g_ {
  font-size: 14px;
  font-weight: 400;
  padding-top: 10px;
  font-family: Helvetica;
  line-height: 17px;
  padding-top: 10px;
}

._nk_un  {
  color: RGB(175, 175, 175);
  margin-left: 5px;
  margin-right: 10px;
  margin-bottom: 10px;
}

._3i_z- {
  color: RGB(111, 111, 111);
  font-size: 12px;
  font-weight: 700;
  font-family: Helvetica;
  padding-top: 15px;
  padding-bottom: 13px;
}

._3TGl0 {
  color: RGB(111, 111, 111);
  font-size: 14px;
  font-family: Helvetica;
  line-height: 17px;
  padding-left: 1em;
  vertical-align: middle;
}

._1HWiX {
  font-size: 14px;
}

._2xuNL {
    border: 0px; 
    font-size: 14px;
    box-shadow: RGB(0 0 0 / 6%) 0px 2px 5px 0px;
    font-weight: 400;
}

._tmYrt {
    color: RGB(57, 175, 83);
    font-size: 22px;
    margin-top: 1px;
}

._2g7lJ {
    color: RGB(255, 81, 81);
    font-size: 22px;
    margin-top: 1px;
}

._Kdnnh {
    color: RGB(175, 175, 175);
}

._1W__4{
    font-size: 12px;
    font-weight: 400;
    font-family: Helvetica;
}

._2rg5k {
    color: RGB(55, 130, 247);
    font-size: 14px;
    font-family: Helvetica;
    padding-top: 7px;
    border-radius: 4px;
    padding-bottom: 7px;
    background-color: RGB(235, 242, 254);
}

._2rg5k:hover {
    cursor: pointer;
}

._1gkZY,
._2Lx08 {
    color: RGB(55, 55, 55);
    font-size: 14px;
    font-weight: 700;
    font-family: Helvetica;
}

._1W__4,
._2GkuX {
    color: RGB(111, 111, 111);
    font-size: 14px;
    font-weight: 700;
}

._2GkuX {
    color: #4D4D4D !important;
}

._u9CPG,
._3GYuS {
    border: 1px solid RGB(215, 215, 215) !important;
    padding-top: .5em;
    padding-bottom: .5em;
    background-color: RGB(213, 215, 215) !important;
}

._u9CPG {
    border: 1px solid RGB(76, 220, 108) !important;
    background-color: RGB(76, 220, 108) !important;
}

._3wCvo {
    border: 1px solid RGB(26, 178, 233) !important;
    padding: .5em;
}

._3JU0t {
    color: RGB(26, 178, 233) !important;
    margin: auto 0;
}

._u9CPG:hover,
._y9kp3:hover,
._3wCvo:hover, 
._Xr9z7:hover,
._3GYuS:hover {
    cursor: pointer ;
}   

._2q7Ml {
    color: #4D4D4D;
    font-size: 14px;
    font-family: Helvetica;
}

._1SPkv {
    color: RGB(255, 255, 255) !important;
    font-size: 2em !important;
    margin-top: .6rem !important;
}

._13Z3t:hover {
    cursor: pointer;
}

._3CS6p,
._19JmH {
    color: RGB(55, 55, 55);
    font-size: 14px;
    font-weight: 700;
    font-family: Helvetica;
}

._3UwRS,
._3XEqa {
    color: RGB(111, 111, 111);
    font-size: 14px;
    font-weight: 700;
}

._3XEqa {
    color: #4D4D4D !important;
}

._qGAkR,
._1i3L_ {
    border: 1px solid RGB(215, 215, 215) !important;
    padding-top: .5em;
    padding-bottom: .5em;
    background-color: RGB(213, 215, 215) !important;
}

._qGAkR {
    border: 1px solid RGB(76, 220, 108) !important;
    background-color: RGB(76, 220, 108) !important;
}

._2-sZb {
  border: 1px solid RGB(26, 178, 233) !important;
  padding: .5em;
}

._qGAkR:hover,
._2AxnC:hover,
._2-sZb:hover, 
._3ztxP:hover,
._1i3L_:hover {
    cursor: pointer ;
}

._GpFmj {
    color: #4D4D4D;
    font-size: 14px;
    font-family: Helvetica;
}

._LcDMm {
    color: RGB(255, 255, 255) !important;
    font-size: 2em !important;
    margin-top: .6rem !important;
}

._uiyb- {
  color: RGB(26, 178, 233) !important;
  margin: auto 0;
}

._3bGei {
  font-size: 16px;
  font-weight: 700;
  font-family: Arial;
}

._2KNl- {
  border: 0px; 
  font-size: 14px;
  box-shadow: RGB(0 0 0 / 6%) 0px 2px 5px 0px;
  font-weight: 400;
}

._rnoOZ {
  color: RGB(111, 111, 111);
  font-size: 14px;
  font-family: Helvetica;
  line-height: 17px;
  padding-left: 1em;
  vertical-align: middle;
}

._3rihG {
  color: RGB(57, 175, 83);
  font-size: 22px;
  margin-top: 1px;
}

._3_aAP {
  color: RGB(255, 81, 81);
  font-size: 22px;
  margin-top: 1px;
}

._1Wlu_ {
  color: RGB(175, 175, 175);
}

._3UwRS{
  font-size: 12px;
  font-weight: 400;
  font-family: Helvetica;
}

._XfhKM {
  color: RGB(55, 130, 247);
  font-size: 14px;
  font-family: Helvetica;
  padding-top: 7px;
  border-radius: 4px;
  padding-bottom: 7px;
  background-color: RGB(235, 242, 254);
}

._1t8HX:hover,
._XfhKM:hover {
  cursor: pointer;
}

._1t8HX {
  color: RGB(255, 255, 255);
  height: auto;
  border: 1px solid RGB(2, 117, 216);
  word-break: break-all;
  border-radius: 4px;
  background-color: RGB(2, 117, 216);
}

._3JQYz {
  color: RGB(26, 178, 233);
  font-size: 18px;
  font-weight: 500;
  font-family: Helvetica;
}

._VSezD {
  width: 1px;
  height: 110%;
  border-left: 1px solid;
  margin-left: 100%;
  margin-top: -1px;
}

._HxII5:hover {
  cursor: pointer;
}

._3xMBf {
  font-size: 14px;
}

._1EyXj {
  color: RGB(33, 37, 41);
  font-size: 16px;
  font-weight: 600;
}

._20M5H,
._zXyH6 {
  color: RGB(111, 111, 111);
  font-size: 12px;
  font-weight: 300;
  line-height: 15px;
  font-family: Helvetica;
}

._2rvPB {
  text-align: -webkit-right;
  justify-content: flex-end;
}

._1f1Ih {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  padding: 12px;
  font-size: 14px;
  border-radius: 20px;
  word-break: break-word;
  white-space: pre-wrap;
}

._396LM {
  background: RGB(238, 238, 238);
}

._3SXUc {
  color: RGB(255, 255, 255) !important;
  background: RGB(35, 145, 255);
}

._1MySW {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: auto;
  padding: 12px;
  font-size: 14px;
  word-break: break-all;
  border-radius: 20px;
}

._3kFnP {
  color: RGB(1, 90, 160);
  font-size: 14px;
}

._vJpXu {
  border: 1px solid RGB(151, 151, 151);
  border-radius: 4px;
}

._vJpXu:hover {
  cursor: pointer;
}

._kc495 {
  color: RGB(255, 255, 255);
  padding-top: 10px !important;
  padding: 7px;
  font-size: 16px;
  margin-top: .5em;
  text-align: center;
  font-family: Helvetica;
  border-radius: 4px;
  background-color: RGB(76, 220, 108);
}

._kc495:hover {
  cursor: pointer;
}

#_1eIyo {
  position: fixed;
  bottom: 0;
  width: 100%;
  color: #ffffff;
}

#_37YeB {
  width: 100% !important;
  height: 100% !important;
  position: fixed;
  top: 0;
  left: 0;
  background-color: #151615;
}

#_37YeB video {
  width: 100% !important;
  height: 100% !important;
}

#_HDapw {
  display: none;
  z-index: 99 !important;
}

#_HDapw video {
  height: 113px;
  transform: scaleX(-1);
  text-align: right;
}

#_bpS2m {
  z-index: 99 !important;
}

#_2ngG0 {
  top: 50%;
  width: 100%;
  color: #fff;
  margin: 0 0 10px 0;
  padding: 5px;
  position: fixed;
  text-align: center;
  background-color: RGBA(0, 0, 0, .3);
  transition: opacity 2s;
}

#_1DzdA {
  position: fixed;
}

._2zhpT {
  background: #ffffff00;
  color: #ffffff;
  border-radius: 50%;
  padding: 0;
  margin: 0;
  height: 75px;
  width: 75px;
}

._2zhpT._2uKn_ {
  color: #ffffff00 !important;
  background: #ffffff00 !important;
}

._2zhpT._19hJh {
  background: #ffffff;
  color: #5a5a5a;
}

._1FmkZ {
  background-color: #f45050;
  border-color: #f23838;
  color: #ffffff;
}

._1umLI {
  background-color: #f45050;
  border-color: #f23838;
  color: #ffffff;
}

._2HcIg,
._nM1qw {
  top: unset !important;
  left: unset !important;
  right: 0 !important;
  position: fixed !important;
  margin-top: 20px !important;
  margin-left: unset !important;
  margin-right: 20px !important; 
}

._TPYrc {
  top: 0 !important;
  left: 0 !important;
  bottom: unset !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
}

._3qL4B,
._tHphQ {
  top: 0 !important;
  left: 0 !important;
  right: unset !important;
  position: fixed !important;
  margin-top: 20px !important;
  margin-left: 20px !important;
  margin-right: unset !important; 
}

._WbDe_ {
  top: unset !important;
  left: 0 !important;
  bottom: 0 !important;
  margin-left: 20px !important;
  margin-bottom: 20px !important;
}

._17aXe {
  color: RGB(55, 130, 247);
  font-size: 14px;
  font-family: Helvetica;
  padding-top: 7px;
  border-radius: 4px;
  padding-bottom: 7px;
  background-color: RGB(235, 242, 254);
}

._Gqst9 {
  color: RGB(247, 55, 55);
  font-size: 14px;
  font-family: Helvetica;
  padding-top: 7px;
  border-radius: 4px;
  padding-bottom: 7px;
  background-color: RGB(254, 235, 235);
}

._17aXe:hover, 
._Gqst9:hover {
  cursor: pointer;
}

._2TtRf {
  position: relative;
  left: 1.5em;
  top: -.4em;
}

._3jmj1:hover {
  cursor: pointer !important;
}

._3T97F::after {
  content: " ";
  background-color: #ee3131;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  position: relative;
  display: block;
  top: -1.6rem;
  left: .6rem;
  -webkit-animation-name: _oSVFK;
          animation-name: _oSVFK;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

._3ohgn {
  width: 10px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;

  font-size: 10px;
  -webkit-animation-name: _oSVFK;
          animation-name: _oSVFK;
  background-color: #ee3131;
  -webkit-animation-duration: 2s;
          animation-duration: 2s;
  -webkit-animation-iteration-count: infinite;
          animation-iteration-count: infinite;
}

._3oBZR {
  width: 15px;
  height: 10px;
  display: inline-block;
  border-radius: 50%;
  font-size: 10px;
  opacity: 0;
}

._5nHm7 {
  width: 1.5em * 6;
  height: 1.5em * 6;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

img._23gBc {
  border: 2px dashed grey;
  background: white;
  width: 1.5em * 3.5;
  height: auto;
  position: relative;
  right: 4px;
}

._2toTK {
	border-radius: 50%;
	width: 100%;
	background: no-repeat;
	background-position: center!important;
	background-size: cover!important;
	background-size: 100%;
	position: relative;	
}

._2toTK:before {
  content: "";
  display: block;
  padding-top: 100%; 
}

._186Aw:hover {
  cursor: pointer;
  background-color: RGB(235, 242, 254);
}

._3gqf9 {
  position: relative;
  z-index: 99 !important
}

._3gqf9:hover {
  cursor: pointer;
  opacity: .5;
}

#_Hif5P {
  color: RGB(55, 55, 55);
  font-size: 16px !important;
  font-weight: 300;
  background-color:  RGB(248, 249, 255) !important;
}

#_Hif5P ul {
  padding: 0;
  list-style-type: none; /* Remove default bullets */
}

#_Hif5P ul li::before {
  font-family: "Font Awesome 5 Free";
  content: "\F14A";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
  color: #008CFF !important; /* Change the color */
  margin-right: 1em;
  width: 12px; /* Also needed for space (tweak if needed) */
} 

#_Hif5P h5 {
  font-weight: 300;
  color: #008CFF !important
}

@-webkit-keyframes _oSVFK {
  0%    {background-color: #ee3131; opacity: 100%;}
  6%    {background-color: rgb(247, 204, 204); opacity: 50%;}
}

@keyframes _oSVFK {
  0%    {background-color: #ee3131; opacity: 100%;}
  6%    {background-color: rgb(247, 204, 204); opacity: 50%;}
}

@media only screen and (min-width: 600px) {
  #_HDapw {
      right: 0;
      bottom: 0;
      position: fixed;
      margin-bottom: 20px;
      margin-right: 20px;
  }

  #_bpS2m {
      right: 0;
      bottom: 0;
      position: fixed;
      margin-bottom: 20px;
      margin-right: 20px;
  }

  #_1DzdA {
      top: 0;
      left: 0;
      margin-left: 20px;
      margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  #_HDapw {
      right: 0;
      position: fixed;
      margin-top: 20px;
      margin-right: 20px;
  }

  #_bpS2m {
      right: 0;
      position: fixed;
      margin-top: 20px;
      margin-right: 20px;
  }

  #_1DzdA {
      top: 0;
      left: 0;
      margin-left: 20px;
      margin-top: 20px;
  }
}